import React from "react";

export const useWindowResize = () => {
  const [windowSize, setWindowSize] = React.useState({width: undefined, height: undefined});  
  React.useEffect(() => {
    if (typeof window !== `undefined`) {

      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
    
      window.addEventListener("resize", handleResize);
      
      handleResize();
      
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [])

  return windowSize
}

export const useOnScreen = (ref) => {
  const [isIntersecting, setIntersecting] = React.useState(false)
  const [y, setY] = React.useState(0);
  const checkScroll = () => {
    if (ref && ref.current) {
      const {y} = ref.current.getBoundingClientRect();
      setIntersecting(y <= 0);
      setY(y);
    }
  }
  // const observer = new IntersectionObserver(
  //   ([entry]) => {
  //     if (shouldLog) {
  //       console.log(entry)
  //     }
  //     setIntersecting(entry.isIntersecting)
  //   }, {threshold: 0.999}
  // )

  React.useEffect(() => {
    window.addEventListener('scroll', checkScroll)
    // observer.observe(ref.current)
    return () => {
    window.removeEventListener('scroll', checkScroll)
    // observer.disconnect()
    }
  }, [])

  return {isIntersecting, y}
}