import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import { HeaderQuery } from "../graphql"
import "../styles/lib/hamburgers.min.css"
import { mediaQueries } from "../styles/mediaQueries"
import { useWindowResize } from "../utilities/hooks"
import { scrollTo } from "../utilities/luchador-utils"
import FullScreenContainer from "./full-screen-container"
import Modal from "./modal"
import igLogo from "../images/instagram.svg"

const StyledHeader = styled.header`
  background: white;
  height: 100%;
  left: 100%;
  position: fixed;
  top: 0;
  width: 300px;
  z-index: 2;
  transition: all 0.5s cubic-bezier(0, 0, 0, 1);
  overflow: visible;
  opacity: 0;
`

const Hamburger = styled.button`
  position: fixed;
  top: 0;
  right: 0;
  -webkit-appearance: div;
  outline: 0;
  z-index: 3;

  &.hamburger--squeeze .hamburger-inner {
    background-color: black;
    height: 2px;
    border-radius: 0;
    &:before,
    &:after {
      height: 2px;
      background-color: black;
      border-radius: 0;
    }
  }
`

const HeaderSections = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  flex-direction: column;
  overflow-y: auto;
  padding: 70px 0 40px;
  width: 100%:
`

const Section = styled.div`
  padding: 0;
  width: 100%;
`

const NavItem = styled.div`
  font-family: "D-DIN Condensed", Helvetica;
  font-weight: bold;
  font-size: 20px;
  width: 100%;
  padding: 1em 2em;
  cursor: pointer;
`

const NavLink = styled.a`
  color: black;
  text-decoration: none;
`

const Header = () => {
  const [activeSection, setActiveSection] = React.useState(undefined)
  const [menuActive, setMenuActive] = React.useState(false)
  const windowSize = useWindowResize()

  const sections =
    typeof document !== `undefined`
      ? document.querySelectorAll('*[id^="section-"][data-title]')
      : []

  const handleClick = (id: string) => {
    setMenuActive(false)
    scrollTo(id)
  }
  return (
    <div>
      <Hamburger
        onClick={() => setMenuActive(!menuActive)}
        className={`hamburger hamburger--squeeze ${
          menuActive ? "is-active" : ""
        }`}
        type="button"
      >
        <span className="hamburger-box">
          <span className="hamburger-inner"></span>
        </span>
      </Hamburger>
      <StyledHeader
        style={{
          opacity: `${menuActive ? 1 : 0}`,
          transform: `${menuActive ? "translateX(-100%)" : ""}`,
        }}
      >
        <HeaderSections>
          <Section>
            {[...sections].map((section, i) => {
              return (
                <NavItem
                  onClick={() => handleClick(section.getAttribute("id"))}
                >
                  {section.getAttribute("data-title")}
                </NavItem>
              )
            })}
            <NavLink href="https://123spirits.myshopify.com/" target="_blank">
              <NavItem>Purchase</NavItem>
            </NavLink>
          </Section>
          <Section></Section>
        </HeaderSections>
      </StyledHeader>
    </div>
  )
}

export default Header
