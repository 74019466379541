import styled from "styled-components"
import { Colors } from "./colors";
import { mediaQueries } from "./mediaQueries";

export const headline1 = `
    font-family: 'D-DIN Condensed', Helvetica;
    font-weight: bold;
    font-size: 35px;
    line-height: 1.11em;
    letter-spacing: 5.25px;
    margin: 0;`

export const headline2 = `
    font-family: 'D-DIN', Helvetica;
    font-weight: bold;
    font-size: 35px;
    line-height: 1.11em;
    margin: 0;`

export const paragraph = `
    font-family: 'D-DIN', Helvetica;
    font-weight: normal;
    font-size: 18px;
    line-height: 1.7em;
    letter-spacing: 0.02em;
`

export const StyledH1 = styled.h1`
    ${headline1}
`;

export const StyledH2 = styled.h1`
    font-family: 'D-DIN Condensed', Helvetica;
    font-size: 22px;
    letter-spacing: 5.25px;
    line-height: 31.5px;
    font-weight: normal;
    margin: 0;
`;

export const StyledP = styled.p`
    ${paragraph};
    margin: 0;
    color: ${props => props.color || Colors.BROWN};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    span.justified {
        display: block;
        text-align: justify;

        &:after {
            content: "";
            display: inline-block;
            width: 100%;
            height: 0px;
        }
    }

    span.post-justified {
        display: inline-block;
        margin-top: -1em;
    }

    ${mediaQueries("tablet")`
        font-size: 24px;
    `};

    ${mediaQueries("small")`
        font-size: 16px;
    `};
`