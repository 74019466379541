import React from "react"
import styled from "styled-components"
import { breakpoints, mediaQueries } from "../styles/mediaQueries"
import { useOnScreen, useWindowResize } from "../utilities/hooks"
import FullScreenComponent from './full-screen-component'

interface FullScreenContainerProps {
  backgroundImage?: string,
  className?: string,
  containerClassName?: string,
  id?: string,
  title?: string,
  ignoreSticky?: boolean,
  alignment?: string,
  style?: object,
}

interface FullScreenContainerComponentProps extends FullScreenContainerProps {
  children: any,
}

const StyledFullScreenContainer = styled.div`
    display: block;
    position: relative;
    min-width: 100vw;
    min-height: 100vh;
    background-size: cover;
    background-image: url("${(props: FullScreenContainerProps) => props.backgroundImage}");
    z-index: 0;

    &.sticky {
      background-attachment: fixed;
    }

    ${mediaQueries("tablet")`
      min-height: auto;
    `};
`

const StyledFullScreenComponent = styled(FullScreenComponent)`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    top: 0;
    left: 0;

    &.sticky {
      position: fixed;
      top: 0;
    }

    &.top {
      align-items: flex-start;
    }

    ${mediaQueries("tablet")`
      top: initial;
      left: initial;
      height: auto;
      position: relative;
    `};
`

const FullScreenContainer = ({ children, className, backgroundImage, title, id, ignoreSticky, alignment, style, containerClassName }: FullScreenContainerComponentProps) => {
  const ref = React.useRef()
  const windowSize = useWindowResize();
  const {isIntersecting} = useOnScreen(ref);
  const isSticky = windowSize.width > breakpoints.tablet && isIntersecting && !ignoreSticky;

  return <div style={{display: 'block'}} ref={ref}>
    <StyledFullScreenContainer id={id}  data-title={title} className={className + (isSticky ? ' sticky' : '')} backgroundImage={backgroundImage} style={style}>
      <StyledFullScreenComponent className={`${isSticky ? 'sticky' : ''} ${alignment} ${containerClassName ? containerClassName : null}`}>
        {children}
      </StyledFullScreenComponent>
    </StyledFullScreenContainer>
  </div>
}

export default FullScreenContainer
