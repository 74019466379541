import { breakpoints } from "../styles/mediaQueries";

export const scrollTo = (id:string) => {
  const el = document.getElementById(id);
  if (el) {
    const {y} = el.getBoundingClientRect();
    const scrollY = window.scrollY;
    window.scroll({
      top: scrollY + y,
      behavior: 'smooth'
    });
  }
}

export const getTransformStyleBasedOnBoxSize = (
  translateXFactor:number, 
  translateYFactor:number, 
  minTranslateWidth:number, 
  minTranslateHeight:number, 
  maxTranslateWidth:number, 
  maxTranslateHeight:number,
  width,
  height,
):object => {
  const translateCutoffWidth = breakpoints.tablet;

  const translateXPercent = width < minTranslateWidth || width <= translateCutoffWidth
      ? 0 
      : width >= maxTranslateWidth
          ? 1
          : (width - minTranslateWidth) / (maxTranslateWidth - minTranslateWidth);

  const translateYPercent = height < minTranslateHeight || width <= translateCutoffWidth
      ? 0 
      : height >= maxTranslateHeight
          ? 1
          : (height - minTranslateHeight) / (maxTranslateHeight - minTranslateHeight);

  return {
      transform: `translate(${translateXPercent * translateXFactor}%, ${translateYPercent * translateYFactor}%)`
  }
}