import { createGlobalStyle } from 'styled-components';


import AvenirLTStdBlackTtf from '../fonts/avenir/AvenirLTStd-Black.ttf';
import AvenirLTStdBlackWoff from '../fonts/avenir/AvenirLTStd-Black.woff';
import AvenirLTStdBookTtf from '../fonts/avenir/AvenirLTStd-Book.ttf';
import AvenirLTStdBookWoff from '../fonts/avenir/AvenirLTStd-Book.woff';
import AvenirLTStdRomanTtf from '../fonts/avenir/AvenirLTStd-Roman.ttf';
import AvenirLTStdRomanWoff from '../fonts/avenir/AvenirLTStd-Roman.woff';

import DDINBoldTtf from '../fonts/din/D-DIN-Bold.ttf'
import DDINBoldWoff from '../fonts/din/D-DIN-Bold.woff'
import DDINItalicTtf from '../fonts/din/D-DIN-Italic.ttf'
import DDINItalicWoff from '../fonts/din/D-DIN-Italic.woff'
import DDINTtf from '../fonts/din/D-DIN.ttf'
import DDINWoff from '../fonts/din/D-DIN.woff'
import DDINCondensedBoldTtf from '../fonts/din/D-DINCondensed-Bold.ttf'
import DDINCondensedBoldWoff from '../fonts/din/D-DINCondensed-Bold.woff'
import DDINCondensedTtf from '../fonts/din/D-DINCondensed.ttf'
import DDINCondensedWoff from '../fonts/din/D-DINCondensed.woff'
import DDINExpBoldTtf from '../fonts/din/D-DINExp-Bold.ttf'
import DDINExpBoldWoff from '../fonts/din/D-DINExp-Bold.woff'
import DDINExpItalicTtf from '../fonts/din/D-DINExp-Italic.ttf'
import DDINExpItalicWoff from '../fonts/din/D-DINExp-Italic.woff'
import DDINExpTtf from '../fonts/din/D-DINExp.ttf'
import DDINExpWoff from '../fonts/din/D-DINExp.woff'


export default createGlobalStyle`
@font-face {
    font-family: 'Avenir LT Std';
    src: url(${AvenirLTStdRomanWoff}) format('woff'),
        url(${AvenirLTStdRomanTtf}) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir LT Std';
    src: url(${AvenirLTStdBlackWoff}) format('woff'),
        url(${AvenirLTStdBlackTtf}) format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir LT Std';
    src: url(${AvenirLTStdBookWoff}) format('woff'),
        url(${AvenirLTStdBookTtf}) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'D-DIN';
    src: url(${DDINBoldWoff}) format('woff'),
        url(${DDINBoldTtf}) format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'D-DIN';
    src: url(${DDINWoff}) format('woff'),
        url(${DDINTtf}) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'D-DIN Exp';
    src: url(${DDINExpWoff}) format('woff'),
        url(${DDINExpTtf}) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'D-DIN Exp';
    src: url(${DDINItalicWoff}) format('woff'),
        url(${DDINItalicTtf}) format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'D-DIN Condensed';
    src: url(${DDINCondensedBoldWoff}) format('woff'),
        url(${DDINCondensedBoldTtf}) format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'D-DIN Condensed';
    src: url(${DDINCondensedWoff}) format('woff'),
        url(${DDINCondensedTtf}) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'D-DIN Exp';
    src: url(${DDINExpBoldWoff}) format('woff'),
        url(${DDINExpBoldTtf}) format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'D-DIN';
    src: url(${DDINExpItalicWoff}) format('woff'),
        url(${DDINExpItalicTtf}) format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}
`;