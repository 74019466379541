export const breakpoints = {
    small: 400,
    tablet: 768,
    medium: 1000,
    maxHeadline: 1500,
    xl: 1920
};

export const aspectRatioBreakpoints = {
    homeBreak: '100/97',
    homeLogoBreak: '100/60',
    square: '1/1',
};

export const mediaQueries = (key: keyof typeof breakpoints, useMin?:boolean) => {
    return (style: TemplateStringsArray | String) =>
        `@media (${useMin ? 'min' : 'max'}-width: ${breakpoints[key]}px) { ${style} }`;
    };

export const aspectRatioMediaQueries = (key: keyof typeof aspectRatioBreakpoints) => {
    return (style: TemplateStringsArray | String) =>
        `@media (max-aspect-ratio: ${aspectRatioBreakpoints[key]}) { ${style} }`;
    };
    
    