/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { ReactNode } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import { GlobalStyles } from "../styles/globalStyles"
import GlobalFonts from '../styles/fonts';
import styled from "styled-components";

interface LayoutProps {
  children: ReactNode
}

const ModalContainer = styled.div`
  z-index: 5000;
`

const Layout = ({ children }: LayoutProps) => {
  return (
    <>
      <GlobalStyles />
      <GlobalFonts />
      <Header />
      <main>{children}</main>
      <footer>
      </footer>
      <ModalContainer id="modal-container" />

    </>
  )
}

export default Layout
